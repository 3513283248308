import {helpers} from 'vuelidate/lib/validators';
import i18n from '@/plugins/i18n';

// Only allow numbers, spaces, periods, dashes, and parentheses in a
// US phone number
export const allowedCharactersPhoneUS = helpers.regex('allowedCharactersPhoneUS', /^[0-9 .\-()]*$/);

/**
 * Gets the error message that corresponds to the given validation result
 *
 * @param {Object} validationResult the validation result is usually defined
 *   in the calling code as $v['value']['valueProperty'] e.g.
 *   $v.address.phoneNumber
 *
 * @returns {String} the validation error or an empty string if there is
 *   no error
 */
export function vErrorMessage(validationResult) {
    if (validationResult?.allowedCharactersPhoneUS === false) {
        return i18n.i18next.t('allowedCharactersPhoneUS');
    }
    return '';
}
